<template>
  <div class="index" :style="{ minHeight: minHeight }">
    <van-sticky>
      <van-nav-bar :placeholder="true" title="我收藏的医学公式" />
    </van-sticky>

    <van-search
      shape="round"
      background="#F6F6F6"
      v-model="searchValue"
      @search="searchOk"
      @clear="
        () => {
          this.getList();
        }
      "
      placeholder="输入医学公式关键词进行搜索"
    />

    <div v-if="list.length !== 0">
      <div
        class="card"
        v-for="item in list"
        :key="item.id"
        @click="routeBtn(item)"
      >
        <div>
          <div class="swipe_title">
            {{ item.chineseName }}
          </div>
          <div class="swipe_title_sub">
            {{ item.englishName }}
          </div>
        </div>

        <van-icon name="arrow" />
      </div>
    </div>

    <van-empty v-else description="暂无内容" />
    <login v-model="loginShow" :title="loginTitle"> </login>
  </div>
</template>
<script>
import { getFormulaList } from "@/service/api";
import { postDataBrowse } from "@/service/api";
import { getAdmins } from "@/utils/adminDate";
import login from "@/components/login.vue";
import { setById } from "@/service/base";
export default {
  name: "medicalMark",
  components: { login },
  data() {
    return {
      searchValue: "",
      minHeight: window.innerHeight - 100 + "px",
      list: [],
      loginShow: false,
      loginTitle: "",
    };
  },
  watch: {
    $route(now) {
      console.log(now);
      this.getById();
      
    },
  },
  mounted() {
    this.$store.commit("medicalMenu_typeIndex", 0);
    this.getList();
  },
  created() {
    if (this.$store.state.memberIdDocument == "") {
      this.loginShow = true;
      this.loginTitle = "登录查看更多内容";
    }
  },
  methods: {
    async getById() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$store.state.memberIdDocument,
        this.$route.query.channel
      );
      await setById(arrays);
      await this.getList();
      //  console.log(this.channel);
    },
    async getList() {
      const res = await getFormulaList(this.searchValue);
      if (res.statuscode === 200) {
        this.list = res.data;
      }
    },
    searchOk() {
      this.getList();
    },
    routeBtn(item) {
      if (this.$store.state.memberIdDocument == "") {
        this.$router.push({
          path: "/formula/" + item.code,
          query: {
            id: item.id,
            channel: this.$route.query.channel,
            type: "h5",
            location: "YXGS_COLLECT_LIST",
          },
        });
      } else {
        this.$router.push({
          path: "/formula/" + item.code,
          query: {
            id: item.id,
            memberId: this.$store.state.memberIdDocument,
            channel: this.$route.query.channel,
            type: "h5",
            location: "YXGS_COLLECT_LIST",
          },
        });
      }

      this.addClickRecord(item);
    },
    async addClickRecord(item) {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$store.state.memberIdDocument,
        this.$route.query.channel
      );
      //点击埋点
      const data = {
        type: "h5", //index表示在首页列表中点击，mix_index表示在专题列表中点击
        contentId: item.id, //为vod时，根据contentId增加浏览量
        contentType: "YXGS_DWHS",
        location: "YXGS_COLLECT_LIST",
        channel: this.$route.query.channel,
        os: arrays.os,
        memberId: this.$store.state.memberIdDocument,
      };
      console.log(data);
      await postDataBrowse(data);
    },
  },
};
</script>

<style scoped lang="scss">
.swipe_title_sub {
  margin-top: 5px;
  color: #999999;
  font-size: 13px;
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 1;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
}
.swipe_title {
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 2;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
}
.index {
  padding-bottom: 100px;
  background: white;
  height: auto;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: #f6f6f6 1px solid;
  }
}
::v-deep .van-search__content {
  background: white !important;
}
</style>
